<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <div v-for="(group, groupIndex) in topics" :key="groupIndex">
      <v-layout justify-start raw wrap>
        <v-flex
          v-for="(topic, topicIndex) in group"
          :key="topicIndex"
          xs12
          sm4
          md4
          lg4
          xl4
        >
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              class="checkboard-card"
              :color="getColor(topic.isBlack)"
              :class="[`${hover ? 'checkboard-card-hover' : ''}`,
                       `elevation-${hover ? 17 : 0}`,
                       {'white--text': topic.isBlack}]"
              @click.native="topic.action"
            >
              <v-card-title primary-title>
                <div>
                  <div class="headline">
                    {{ topic.title }}
                  </div>
                  <v-icon class="lock">
                    {{ topic.icon }}
                  </v-icon>
                  <div class="spaned">
                    {{ topic.description }}
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      topics: 'navigation/engineering'
    })
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
    } else {
        localStorage.setItem('reloaded', '1');
        location.reload();
    };
    // Store mutations
    let title = 'Engineering';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newEng =true;
    this.$store.commit('navigation/replaceEng', newEng);
  },
  methods: {
    getColor(isBlack) {
    return isBlack ? '#2E2E2E' : '#e0e0e0';
    }
  },
  beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => { vm.$store.commit('navigation/leftArrow', false)});
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.commit('navigation/leftArrow', true);
    console.log('lelele')
    next();
  },
  metaInfo() {
  return{ title: 'Technology',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Advanced Topics'}
                ],
        }
   }
}
</script>
<style lang="scss">
@import '@/styles/screen-sizes.scss';
</style>
